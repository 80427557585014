import axios from 'axios';

var axiosConfig = {
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  }
}

if(axiosConfig.baseURL === undefined){
  if (process.env.REACT_APP_ENV === "PHONER"){
    axiosConfig.baseURL = 'https://pro.phonerbusiness.com/api'
  }
  if (process.env.REACT_APP_ENV === "developement"){
    axiosConfig.baseURL = 'http://127.0.0.1:8070'
  }
  if (process.env.REACT_APP_ENV === "developementBusiness"){
    axiosConfig.baseURL = 'http://127.0.0.1:8070'
  }
}

export default axios.create(axiosConfig);
