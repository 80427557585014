import React, {Component} from "react";
import {Layout} from "antd";
import {toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import UserProfileTop from "../Sidebar/UserProfileTop";

import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {connect} from "react-redux";

const {Header} = Layout;

class Topbar extends Component {
  render() {
    const {width, navCollapsed, navStyle} = this.props;
    return (
        <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-3">
              <i style = {{color: 'white'}} className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav(!navCollapsed);
                 }}
              />
            </div> : null}
            <div className="gx-d-block gx-d-lg-none">
            
            </div>
          <ul className="gx-header-notifications gx-ml-auto">
            <li className="gx-user-nav"><UserProfileTop/></li>
          </ul>
        </Header>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, navCollapsed, width} = settings;
  return {navStyle, navCollapsed, width}
};

export default connect(mapStateToProps, {toggleCollapsedSideNav})(Topbar);
