
import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  render() {
    const {themeType, navStyle, pathname, authUser} = this.props;
    const selectedKeys    = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const isBack          = authUser ? authUser.Adm : false;
    console.log("isBack : "+isBack)
    //const isBack = true;

    return (

      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
        mode="horizontal">

        <Menu.Item key="dashboard">
          <Link to="/dashboard"><i className="icon icon-home"/>
          </Link>
        </Menu.Item>

        {isBack  &&
          <Menu.Item key="devis">
            <Link to="/devis"><i className="icon icon-shopping-cart"/>
              <IntlMessages id="sidebar.devis"/></Link>
          </Menu.Item>
        }
        {isBack  &&
        <Menu.Item key="intervention">
          <Link to="/intervention"><i className="icon icon-visits"/>
            <IntlMessages id="sidebar.interventions"/></Link>
        </Menu.Item>
        }
        {isBack  &&
        <Menu.Item key="client">
          <Link to="/client"><i className="icon icon-signup"/>
            <IntlMessages id="sidebar.client"/></Link>
        </Menu.Item>
        }
        {isBack &&
        <Menu.Item key="flotte">
          <Link to="/flotte_client"><i className="icon icon-crm"/>
            <IntlMessages id="sidebar.Flotte"/></Link>
        </Menu.Item>
        }
        {isBack &&
        <Menu.Item key="sim_all">
          <Link to="/sim_all"><i className="icon icon-crm"/>
            <IntlMessages id="sidebar.Sim"/></Link>
        </Menu.Item>
        }
        {isBack  &&
        <SubMenu key="general" className={this.getNavStyleSubMenuClass(navStyle)}
        title={
          <span>
            <i className="icon icon-orders"/>
            <IntlMessages id="sidebar.components.facture"/>
          </span>
        }>
          <Menu.Item key="facture">
            <Link to="/facture"><i className="icon icon-shopping-cart"/>
              <IntlMessages id="sidebar.B2B.Facture"/></Link>
          </Menu.Item>

          <Menu.Item key="avoir">
            <Link to="/avoir"><i className="icon icon-orders"/>
              <IntlMessages id="sidebar.avoir"/></Link>
          </Menu.Item>

          <Menu.Item key="remise">
            <Link to="/remise"><i className="icon icon-orders"/>
              <IntlMessages id="sidebar.remise"/></Link>
          </Menu.Item>

          <Menu.Item key="bontreso">
            <Link to="/bontreso"><i className="icon icon-orders"/>
              <IntlMessages id="sidebar.bontreso"/></Link>
          </Menu.Item>
        </SubMenu>
        }
        {isBack  &&
        <Menu.Item key="commande_fournisseur">
          <Link to="/commande_fournisseur"><i className="icon icon-camera-2"/>
            <IntlMessages id="sidebar.commande_fournisseur"/></Link>
        </Menu.Item>
        }
        {isBack  &&
        <Menu.Item key="modele">
          <Link to="/modele"><i className="icon icon-camera-2"/>
            <IntlMessages id="sidebar.modele"/></Link>
        </Menu.Item>
        }
        {isBack  &&
          <Menu.Item key="article">
            <Link to="/article"><i className="icon icon-camera-2"/>
              <IntlMessages id="sidebar.article"/></Link>
          </Menu.Item>
        }
        {isBack  &&
          <Menu.Item key="stock">
            <Link to="/stock"><i className="icon icon-camera-2"/>
              <IntlMessages id="sidebar.stock"/></Link>
          </Menu.Item>
        }
        {isBack  &&
          <Menu.Item key="composant">
            <Link to="/composant"><i className="icon icon-camera-2"/>
              <IntlMessages id="sidebar.composant"/></Link>
          </Menu.Item>
        }

        {!isBack  &&
          <Menu.Item key="boutique">
            <Link to="/achat_telephone">
              <IntlMessages id="sidebar.B2B.Boutique"/></Link>
          </Menu.Item>
        }

        {!isBack  &&
          <Menu.Item key="ligne_mobile">
            <Link to="/ligne_mobile">
              <IntlMessages id="sidebar.B2B.Ligne_Mobile"/></Link>
          </Menu.Item>
        }
        {!isBack  &&
          <Menu.Item key="commande_client">
            <Link to="/commande_client">
              <IntlMessages id="sidebar.B2B.Commande"/></Link>
          </Menu.Item>
        }
        {!isBack  &&
        <Menu.Item key="facture">
          <Link to="/facture">
            <IntlMessages id="sidebar.B2B.Facture"/></Link>
        </Menu.Item>
        }
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, pathname, locale} = settings;
  return {themeType, navStyle, pathname, locale}
};
export default connect(mapStateToProps)(HorizontalNav);
