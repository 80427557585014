import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link} from "react-router-dom";

import {connect} from "react-redux";
import {newPassword} from "../appRedux/actions/Auth";
import {message} from 'antd';
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class NewPassword extends React.Component {


  constructor(props) {

    super(props);

    this.state = {

      GUID:'',
      isHidenPassword : false,
      hidenPassword : 'password',

    }

  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("values", values);
      var isOK = true;

      // Control si mot de passe identique
      if(values.Password === values.Password_confirm){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être identique !!!!')
        this.SendMessageError('Les mots de passe doivent être identiques!');
        return;
      }

      console.log('Val ' + values.length);
      // Control la taille des mots de passes
      if(values.Password.length >= 10){
        isOK = true;
      }else{
        isOK = false
        console.log('La longueur du mot de passe doit être supérieure à 10 caractères !')
        this.SendMessageError('La longueur du mot de passe doit être supérieure à 10 caractères !');
        return;
      }

      // Control la taille des mots de passes
      if(values.Password.length <= 30){
        isOK = true;
      }else{
        isOK = false
        console.log('La longueur du mot de passe doit être inférieure à 30 caractères !')
        this.SendMessageError('La longueur du mot de passe doit être inférieure à 30 caractères !');
        return;
      }


      values.GUID = this.state.GUID;

      if (!err) {
        this.props.newPassword(values);
      }
    });
  };

  SendMessageError(pe_message){
    message.error(pe_message);
  }

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }

    const location = window.location.href

    const path = location.split('/');

    const GUID_ = path[path.length - 1]


    this.state.GUID =GUID_;

  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                  <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.ChangePassword.title"/></h1>
                <p><IntlMessages id="app.userAuth.ChangePassword.description"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">

              <FormItem>
                {getFieldDecorator('Password', {
                  rules: [{required: true, message: 'Entrer votre mot de passe!'}],
                })(
                  <Input type="password" placeholder="Mot de passe"/>
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('Password_confirm', {
                  rules: [{required: true, message: 'Re-rentrer votre mot passe!'},
                ],
                })(
                  <Input type="password" placeholder="Mot de passe"/>
                )}
              </FormItem>



              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.ChangePassword.action"/>
                </Button>
              </FormItem>

              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );

  }
}

const WrappedNewPasswordForm = Form.create()(NewPassword);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {newPassword})(WrappedNewPasswordForm);
