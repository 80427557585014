import React from "react";
import {Button, Form, Input} from "antd";
import {connect} from "react-redux";

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {

  //  console.log('process.env.REACT_APP_ENV');
  //  console.log(process.env.REACT_APP_ENV);
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }



  getTextSignIn(){
    // bySigning

    //  bySigningSOGESCOT

    if(process.env.REACT_APP_ENV == 'SOGESCOT'){
      return "app.userAuth.bySigningSOGESCOT";
    }else{
      return "app.userAuth.bySigning";
    }

  }


  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                <p><IntlMessages id={this.getTextSignIn()}/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: `Cette adresse e-mail n'est pas valide`,
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{required: true, message: 'Entrer votre mot de passe'}],
                  })(
                    <Input type="password" placeholder="Mot de passe"/>
                  )}
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <a href={'https://phonerbusiness.com/audit-gratuit/'} target={"_blank"}><IntlMessages id="app.userAuth.demandeCreation"/></a>
                </FormItem>

              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
