import React from "react";
import {Select, Tag, Tooltip} from "antd";
import joursFeries from "@socialgouv/jours-feries";

const Option = Select.Option;




export function renderSwitchEtatComposant(param) {
  switch(param) {
    case 'Vendu': return 'orange';
    case 'Disponible': return 'green';
    case 'En location': return 'geekblue';
    case 'Transit Navette': return 'lime';
    case 'Réformé': return 'red';
    case 'En inventaire': return 'cyan';
    default: return '';
  }
}


export function  generateRandomNumber (min, max) {
    return Math.floor(Math.random()*(max-min+1)+min)
  }


export function  calculTTC_VIA_HT (pe_IsTVA_SurMarge, pe_ht, pe_tva) {

    let prixTTC = 0;

    if(pe_IsTVA_SurMarge){
        prixTTC = Number(pe_ht);
    }else{
      pe_ht = Number(pe_ht);
      prixTTC  = pe_ht +  (pe_ht / 100 *  Number(pe_tva));
      prixTTC  = prixTTC.toFixed(2);
    }

    return prixTTC;

  }


/*
  Retourne le contenu d'un champ de type Array d'un objet (obj[array])
  Si l'objet ou le champ est null, undefined ou '', alors retourne []
*/
export function getObjectArrayContent(obj, array) {
  if(isObjectNull(obj) || isObjectNull(obj[array]))
    return [];
  return cleanJSONContent(obj[array]);
}

/*
  Retourne 'true' si une variable est null, undefined ou '', et 'false' sinon
*/
export function isObjectNull(item) {
  if(item === undefined || item === null || item === '')
    return true;
  return false;
}

/*
  Retourne la valeur de l'objet s'il est non null, undefined ou '' et 'valueIfNull' sinon
*/
export function getValueIfNull(object, valueIfNull) {
  return (isObjectNull(object) ? valueIfNull : object);
}

export function truncateString(str, num = 40) {
  return str.length <= num ? str : str.slice(0, num) + '...';
}

export function isJourFerieOrSunday(day) {
  if(day.day() === 0) //dimanche
    return true;
  day = day.startOf("day").utc().toDate();

  var d = new Date(day);
  let joursFeriesList = joursFeries(d.getFullYear());

  for(let jourFerie in joursFeriesList) {
    if(day.getTime() === joursFeriesList[jourFerie].getTime())
      return true;
  }
}

export function getMarge(pe_PrixHT, pe_IsTvaMarge, pe_Derpa, pe_DerpaTVASurMarge) {
  //console.log('getPourcentageMarge->' + pe_PrixHT, pe_IsTvaMarge, pe_Derpa, pe_DerpaTVASurMarge);
  if(pe_IsTvaMarge == 1)
    return (pe_PrixHT - pe_DerpaTVASurMarge).toFixed(2);
  return (pe_PrixHT - pe_Derpa).toFixed(2);
}

export function getPourcentageMarge(pe_PrixHT, pe_IsTvaMarge, pe_Derpa, pe_DerpaTVASurMarge) {
  // Marge brut HT  / PVHT
  if(pe_IsTvaMarge == 1)
    return (((pe_PrixHT - pe_DerpaTVASurMarge) / pe_PrixHT) * 100).toFixed(2);
  return (((pe_PrixHT - pe_Derpa) / pe_PrixHT) * 100).toFixed(2);
}

export function getBoolListToOptions() {
  var list = [];
  list.push(<Option key={1} value={1}>OUI</Option>);
  list.push(<Option key={0} value={0}>NON</Option>);
  return list;
}

export function getBoolListToOptionsFilter() {
  var list = [];
  list.push(<Option key={-1} value={-1}>Tous</Option>);
  list.push(<Option key={1} value={1}>OUI</Option>);
  list.push(<Option key={0} value={0}>NON</Option>);
  return list;
}

export function getListToOptions(list, idItem, libelleItem) {

  //console.log(list)
  //console.log(idItem)
  //console.log(libelleItem)
  var listToOptions = [];
  for (let i=0; i<list.length; i++) {
    let value = parseInt(list[i][idItem]);
    if(isNaN(value))
      value = list[i][idItem];
    let libelle = list[i][libelleItem];
    listToOptions.push(<Option value={value} key={value}>{libelle}</Option>);
  }
  return listToOptions;
}

export function getLibelleFromOptionsList(list, id) {
  for(let item in list) {
    if(list[item].props.value === id)
      return list[item].props.children;
  }
  return '';
}

export function addAllValueToOptions(list) {
  return [(<Option value={0} key={0}>{"Tous"}</Option>)].concat(list);
}

export function addChoiceInfoToOptions(list, libelle) {
  return [(<Option value={0} key={0}>{libelle}</Option>)].concat(list);
}

export function convertToStatusList(list, id, value) {

  if(list == undefined){
    list = [];
  }

  const convertedList = list.map(item => {
    return {key: item[id], value: item[value]};
  });

  return convertedList;
}

export function concatNomPrenom(nom, prenom) {
  return nom + ' ' + prenom;
}

export function concatNomPrenomSociete(nom, prenom, societe) {

  if(societe != ''){
      return societe;
  }else{
      return nom + ' ' + prenom;
  }



}

export function getFrDateFormat(date) {
  if(date === undefined || date === null)
    return null;
  return formatDate(new Date(date),'dd/MM/yyyy');
}

export function getFrDateFormatHeure(date) {
  if(date === undefined || date === null)
    return null;
  return formatDate(new Date(date),'dd/MM/yyyy hh:mm:ss');
}

export function formatDate(x, y) {
  if(x.getTime() === 0)
    return null;
  if(x.getFullYear() === 1970)
    return '-'

  var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
  });

  return y.replace(/(y+)/g, function(v) {
      return x.getFullYear().toString().slice(-v.length)
  });
}

export function formatCurrency(euros) {

  if(euros == undefined){
    return '';
  }else{
    return ((euros*100) / 100).toFixed(2).toLocaleString() + "€"
  }

}

export function formatCurrencyWithPlus(euros) {



  if(euros == undefined){
    return '';
  }else{
    let value = ((euros*100) / 100).toFixed(2).toLocaleString();
     if(value >= 0) {
        return '+'+ ((euros*100) / 100).toFixed(2).toLocaleString() + "€"
     }else{
       return ((euros*100) / 100).toFixed(2).toLocaleString() + "€"
     }

  }

}

export function cleanJSONContent(data) {
  if(data === null || data === "" || data === undefined) {
    return [];
  }

  return JSON.parse(JSON.stringify(eval("(" + data + ")")))
}

export function parseJSONItem(list, itemName) {
  for(var i in list) {
    list[i][itemName] = cleanJSONContent(list[i][itemName]);
  }
  return list;
}

export function getSwitchEtat(status) {
  switch(status) {
    case 1: return 'geekblue';
    case 2: return 'orange';
    case 3: return 'green';
    case 4: return 'purple';
    default: return 'green';
  }
}

export function getColorMarge(pe_marge) {

  if(pe_marge > 60){
    return 'green';
  }

  if(pe_marge > 50){
    return 'geekblue';
  }
  if(pe_marge > 15){
    return 'orange';
  }

  return 'red';

}

export function getColorResultatFinancier(pe_val) {

  if(pe_val >= 0){
    return 'green';
  }else{
    return 'red';
  }

}

export function getColorStock(pe_qte, pe_stockMini, pe_stockMaxi) {

  if(pe_qte > pe_stockMaxi){
    return 'geekblue';
  }

  if(pe_qte < pe_stockMini){
    return 'red';
  }
  if(pe_qte == pe_stockMini){
    return 'orange';
  }

  if(pe_qte > pe_stockMini){
    return 'green';
  }

  return '';

}


export function getColorStockDemo(pe_qte) {

  if(pe_qte > 0){
    return 'green';
  }else{
    return 'red'
  }

}


export function getMoisWeekToNumero(month, week) {
  let value = '';


  switch (month) {
    case 1:
      value = 'Jan';
      break;
    case 2:
      value = 'Fév'
        break;
    case 3:
      value = 'Mars'
        break;
    case 4:
      value = 'Avr'
        break;
    case 5:
      value = 'Mai'
        break;
    case 6:
      value = 'Juin'
        break;
    case 7:
      value = 'Juil'
        break;
    case 8:
      value = 'Aout'
        break;
    case 9:
      value = 'Sept'
        break;
    case 10:
      value = 'Oct'
        break;
    case 11:
      value = 'Nov'
        break;
    case 12:
      value = 'Déc'
        break;
    default:

  }

  return value + ' n°' +week ;

}


export function getMoisToNumero(pe_Numero) {
  let value = '';


  switch (pe_Numero) {
    case 1:
      value = 'Janvier';
      break;
    case 2:
      value = 'Février'
        break;
    case 3:
      value = 'Mars'
        break;
    case 4:
      value = 'Avril'
        break;
    case 5:
      value = 'Mai'
        break;
    case 6:
      value = 'Juin'
        break;
    case 7:
      value = 'Juillet'
        break;
    case 8:
      value = 'Aout'
        break;
    case 9:
      value = 'Septembre'
        break;
    case 10:
      value = 'Octobre'
        break;
    case 11:
      value = 'Novembre'
        break;
    case 12:
      value = 'Décembre'
        break;
    default:

  }

  return value;

}


export function getColorChargeFixes(idCharge) {
  switch(idCharge) {
    case 1:
      return 'green';
    case 2:
      return 'red';
    case 3:
      return 'orange';
    case 4:
      return 'geekblue';
    case 5:
      return 'purple';
    case 6:
      return 'grey';
    case 7:
      return 'indigo';
    case 7:
      return 'black';
    default:
      return 'green';
  }
}



export function getColorTypeCompteBancaire(pe_const) {
  switch(pe_const) {
    case 'checking':
      return 'green';
    case 'card':
      return 'magenta';
    default:
      return '';
  }
}

export function getColorSoldeCompteBancaire(pe_val) {
  if(pe_val >= 0){
    return 'green';
  }else{
    return 'volcano';
  }
}


export function getColorSoldeCompteBancaireDashboard(pe_val) {
  console.log(pe_val);

  if(pe_val >= 0){
    return 'white';
  }else{
    return 'red';
  }
}




export function getBoolToString(param) {
  return ((param === 1 || param === '1' || param === true) ? 'OUI' : 'NON');
}

export function getBoolToColor(param) {
  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ? 'green' : 'blue');
}

export function getBoolToColorRemise(param) {
  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ? 'orange' : 'green');
}

export function getFormatedTVA(isTVASurMarge) {
  isTVASurMarge = parseInt(isTVASurMarge);
  let color = (isTVASurMarge === 1 ? 'geekblue' : 'green');
  let content = (isTVASurMarge === 1 ? 'Sur marge' : 'Standard');
  return (
    <Tag color={color}>{content}</Tag>
  );
}

export function getFormatedPriceWithTVA(isTVASurMarge, montant, placement='topLeft') {
  isTVASurMarge = parseInt(isTVASurMarge);
  return (
    <Tooltip placement={placement} title={isTVASurMarge === 0 ? "TVA standard" : "TVA sur marge"}>
      <Tag color={isTVASurMarge === 0 ? 'green' : 'blue'}>{formatCurrency(montant)}</Tag>
    </Tooltip>
  )
}
