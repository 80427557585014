import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link} from "react-router-dom";

import {connect} from "react-redux";
import {userSignUp,checkGUID} from "../appRedux/actions/Auth";
import {message} from 'antd';

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      isPending : true,
      user : {Email:""},
      GUID:""
    }
  }




  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      var isOK = true;

      // Control si mot de passe identique
      if(values.Password === values.Confirm_Password){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être identique !!!!')
        this.SendMessageError('Les mots de passes doivent être identique!');
        return;
      }

      console.log('Val ' + values.length);
      // Control la taille des mots de passes
      if(values.Password.length >= 10){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être supérieur à 10 caractères !!!!')
        this.SendMessageError('Les mots de passes doivent être supérieur à 10 caractères !');
        return;
      }

      // Control la taille des mots de passes
      if(values.Password.length <= 30){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être inférieur à 30 caractères !!!!')
        this.SendMessageError('Les mots de passes doivent être inférieur à 30 caractères !');
        return;
      }

      if(isOK){
        console.log("values", values);
        values.GUID = this.state.GUID
        if (!err) {
          this.props.userSignUp(values);
        }
      }


    });
  };

SendMessageError(pe_message){
  message.error(pe_message);
}


  //RECUPERATION DU GUID AVANT MOTNAGE DU COMPOSANT
  async componentDidMount() {
    const location = window.location.href
    const path = location.split('/');
    const GUID = path[path.length - 1]
    console.log(GUID)
    checkGUID(GUID)
    .then((user)=>{
      this.setState({ isValid:true ,isPending:false , GUID:GUID , user:user.compte})
      console.log(user.compte)
    }).catch(error=>{
      window.location.replace("/")
      this.setState({ isValid:false ,isPending:false})
      console.log(error)
    });
  }


  comparePassword(pe_pass){
    console.log('Password ' + pe_pass)
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    if(this.state.isPending){
      return (
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <InfoView/>
            </div>
          </div>
        </div>
      );


    }else if(this.state.isValid){
      return (
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                  <img src='https://via.placeholder.com/272x395' alt='Neature'/>
                </div>
                <div className="gx-app-logo-wid">
                  <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                  <p><IntlMessages id="app.userAuth.bySigning"/></p>
                </div>
                <div className="gx-app-logo">
                  <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
                </div>
              </div>
              <div className="gx-app-login-content">
                <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                  <FormItem>
                    {getFieldDecorator('Email', {
                      rules: [{
                         type: 'email', message: 'Cet email n est pas valide!',
                      }],
                    })(
                      <Input disabled="disabled" placeholder={this.state.user ? this.state.user.Email : "Loading"}/>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('Password', {
                      rules: [{required: true, message: 'Entrer votre mot de passe!'}],
                    })(
                      <Input type="password" placeholder="Mot de passe"/>
                    )}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator('Confirm_Password', {
                      rules: [{required: true, message: 'Re-rentrer votre mot passe!'},
                    ],
                    })(
                      <Input type="password" placeholder="Mot de passe"/>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('remember', {
                      valuePropName: 'checked',
                      initialValue: true,
                    })(
                      <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                    )}
                    <span className="gx-link gx-signup-form-forgot"><IntlMessages
                      id="appModule.termAndCondition"/></span>
                  </FormItem>
                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      <IntlMessages id="app.userAuth.signUp"/>
                    </Button>
                    <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                    id="app.userAuth.signIn"/></Link>
                  </FormItem>
                </Form>
              </div>
              <InfoView/>
            </div>
          </div>
        </div>
      );
    }
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignUp})(WrappedSignUpForm);
