import jwt_decode from 'jwt-decode';
import {
  INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET,
  USER_REGISTERING_SET,
  USER_DASHBOARD_DATA,
  USER_INTERVENTION_DATA,
  USER_INTERVENTION_DATA_CLEAN,
  USER_FACTURE_DATA,
  USER_FACTURE_DATA_CLEAN,
  USER_MODELE_DATA,
  USER_MODELE_DATA_CLEAN,
  USER_AVOIR_DATA,
  USER_AVOIR_DATA_CLEAN,
  USER_REMISE_DATA,
  USER_REMISE_DATA_CLEAN,
  USER_TELEPHONE_LIST_DATA,
  USER_PANIER_ACHAT_DATA,
  USER_PANIER_ACHAT_DATA_CLEAN,
  USER_FLOTTE_DATA,
  USER_FLOTTE_DATA_CLEAN,
  USER_SIM_DATA,
  USER_SIM_DATA_CLEAN,
  USER_GENERATE_PARAM_LIST,
  USER_ITEM_CART,
  USER_ITEM_CART_CLEAN
} from "../../constants/ActionTypes";

//CHECK TOKEN
var token = JSON.parse(localStorage.getItem('token'))
if(token){
  var decoded = jwt_decode(token);
  if(new Date().getTime() > decoded.exp*1000){
    localStorage.removeItem("token");
    localStorage.removeItem("user")
  }
}

export const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  guid:'',
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),

  etatNavette_LIST: [],
  etatReconditionnement_LIST: [],
  etatDevis_LIST: [],
  etatPrelevement_LIST: [],

  intervention_LIST:[],
  intervention_LIST_OFFSET:0,
  intervention_LIST_QUANTITY:20,

  facture_LIST:[],
  facture_LIST_OFFSET:0,
  facture_LIST_QUANTITY:20,

  avoir_LIST:[],
  remise_LIST:[],

  telephone_LIST:[],

  modele_LIST:[],
  modele_LIST_OFFSET:0,
  modele_LIST_QUANTITY:20,

  dashboard_LIST: [],

  registering_account:null,

  panier_achat_LIST:[],

  flotte_LIST:[],

  sim_LIST:[],
  sim_LIST_OFFSET:0,
  sim_LIST_QUANTITY:20,

  totalCartItem : 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {...state, initURL: action.payload}
    }

    case SIGNOUT_USER_SUCCESS: {
      return {...state, token: null, authUser: null, initURL: ''}
    }

    case USER_DATA: {
      return {...state, authUser: action.payload}
    }

    case USER_DASHBOARD_DATA: {
      return {...state, dashboard_LIST: action.payload}
    }

    case USER_AVOIR_DATA: {
      return {...state, avoir_LIST: removeDuplicates(state.avoir_LIST.concat(action.payload))};
    }

    case USER_REMISE_DATA: {
      return {...state, remise_LIST: removeDuplicates(state.remise_LIST.concat(action.payload))};
    }

    case USER_INTERVENTION_DATA: {
      return {...state, intervention_LIST: removeDuplicates(state.intervention_LIST.concat(action.payload)), intervention_LIST_OFFSET:action.offset};
    }

    case USER_FACTURE_DATA: {
      return {...state, facture_LIST: removeDuplicates(state.facture_LIST.concat(action.payload)), facture_LIST_OFFSET:action.offset};
    }

    case USER_MODELE_DATA: {
      return {...state, modele_LIST: removeDuplicates(state.modele_LIST.concat(action.payload)), modele_LIST_OFFSET:action.offset};
    }

    case USER_TELEPHONE_LIST_DATA: {
      return {...state, telephone_LIST: removeDuplicates(state.telephone_LIST.concat(action.payload))};
    }

    case USER_PANIER_ACHAT_DATA: {
      return {...state, panier_achat_LIST: removeDuplicates(state.panier_achat_LIST.concat(action.payload))};
    }

    case USER_FLOTTE_DATA: {
      return {...state, flotte_LIST: removeDuplicates(state.flotte_LIST.concat(action.payload))};
    }

    case USER_FLOTTE_DATA_CLEAN: {
      return {...state, flotte_LIST: []};
    }

    case USER_SIM_DATA: {
      return {...state, sim_LIST: removeDuplicates(state.sim_LIST.concat(action.payload))};
    }


    case USER_ITEM_CART: {
      //console.log('USER_ITEM_CART !!!!!!!!!!!!->' + action.totalCartItem);
      return {...state, totalCartItem: action.totalCartItem};
    }


    case USER_ITEM_CART_CLEAN: {
      return {...state, totalCartItem: 0};
    }



    case USER_SIM_DATA_CLEAN: {
      return {...state, sim_LIST: []};
    }

    case USER_PANIER_ACHAT_DATA_CLEAN: {
      return {...state, panier_achat_LIST: []};
    }

    case USER_FACTURE_DATA_CLEAN: {
      return {...state, facture_LIST: [], facture_LIST_OFFSET:action.offset};
    }

    case USER_AVOIR_DATA_CLEAN: {
      return {...state, avoir_LIST: []};
    }

    case USER_REMISE_DATA_CLEAN: {
      return {...state, remise_LIST: []};
    }

    case USER_MODELE_DATA_CLEAN: {
      return {...state, modele_LIST: [], modele_LIST_OFFSET:action.offset};
    }

    case USER_INTERVENTION_DATA_CLEAN: {
      return {...state, intervention_LIST: [], intervention_LIST_OFFSET:action.offset};
    }

    case USER_TOKEN_SET: {
      return {...state, token: action.payload};
    }

    case USER_REGISTERING_SET: {
      return {...state, registering_account: action.payload};
    }

    case USER_GENERATE_PARAM_LIST: {
      return {...state, [action.listType]: action.payload};
    }

    default:
      return state;
  }
}

function removeDuplicates(json) {
  const jsonObject = json.map(JSON.stringify);
  const uniqueSet = new Set(jsonObject);
  const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  return uniqueArray;
}
