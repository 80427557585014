import {NAV_STYLE_FIXED, TAB_SIZE} from "../../../constants/ThemeSetting";
import React, {Component} from "react";
import {Layout, Select, Button} from 'antd';
import {connect} from "react-redux";

import UserInfo from "components/UserInfo";
import {toggleCollapsedSideNav, updateWindowWidth, onNavStyleChange} from "../../../appRedux/actions/Setting";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";

const {Header} = Layout;

class BelowHeader extends Component {

  componentDidMount() {
    window.addEventListener('resize', () => {
      //this.props.updateWindowWidth(window.innerWidth)
      if(window.innerWidth < TAB_SIZE)
        this.props.onNavStyleChange(NAV_STYLE_FIXED)
    });
  }

  render() {
    const {navCollapsed} = this.props;
    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark gx-below-header-horizontal">
        <Header className="gx-header-horizontal-main">
          <div className="gx-container">
            <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav(!navCollapsed);
                 }}
              />
            </div>
              <div className="gx-d-block gx-d-lg-none gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
                <img alt="" src={require("assets/images/w-logo.png")}/>
              </div>
              <div to="/" className="gx-d-none gx-d-lg-block gx-mr-xs-5 gx-logo">
                <img alt="" src={require("assets/images/w-logo.png")}/>
              </div>
              <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-user-nav"><UserInfo/></li>
              </ul>
            </div>
          </div>
        </Header>
        <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
          <div className="gx-container">
            <div className="gx-header-horizontal-nav-flex">
              <HorizontalNav/>
              <div className="gx-header-notifications gx-ml-auto">
                <div style={{marginRight: 18}}>
                  <Link to="/panier_achat"><Button type="primary" icon="shopping-cart"></Button></Link>
                </div>
                <Link to="/dashboard"><Button type="primary" icon="star">Support</Button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed} = settings;
  return {navCollapsed}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, updateWindowWidth, onNavStyleChange})(BelowHeader);
