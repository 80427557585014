import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover, Button, Badge} from "antd";
import {Link} from "react-router-dom";
import {userSignOut} from "appRedux/actions/Auth";
import {updateCartItem} from "appRedux/actions/Auth";

class UserProfileTop extends Component {


  componentDidMount() {

    this.props.updateCartItem();

  }

  render() {

    const {authUser, totalCartItem} = this.props;


    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut()}>Se déconnecter
        </li>
      </ul>
    );



    /*
    <Link to="/panier_achat" >
      <div style = {{marginTop:6}}>
        <Button type="primary" shape="circle" icon="shopping-cart"/>
        <Badge count={12} style={{backgroundColor: 'green', marginTop:-24}}/>
      </div>
    </Link>
    */

    return (
      <div className="gx-flex-row gx-align-items-center ">
        <div style={{ marginTop: 12, marginRight: 18 }}>
          <Link to="/panier_achat">
            <Button type="primary" shape="circle" icon="shopping-cart"/>
            <Badge count={totalCartItem} style={{backgroundColor: 'green', marginTop:-24, marginLeft : -20}}/>
          </Link>
        </div>

      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <span className="gx-pointer gx-flex-row gx-align-items-center">
          <div style={{ color: "#ffffff" }}>
          <span className="">{authUser ? authUser.Nom + ' ' + authUser.Prenom  : "Loading"}</span>
          </div>
          <div style={{color: "#ffffff", marginRight: 18 }}>
            <i className="icon icon-chevron-down gx-pl-2"/>
          </div>
        </span>
      </Popover>


      </div>
    )
  }
}

/*
<Avatar src={require(`assets/images/${process.env.REACT_APP_ENV}/profil.jpg`)}
      className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
*/
const mapStateToProps = ({auth}) => {
  const {authUser, totalCartItem} = auth;
  return {authUser, totalCartItem}
};

export default connect(mapStateToProps, {userSignOut,updateCartItem})(UserProfileTop);
